<template>
  <NoisePage />
</template>

<script>
import NoisePage from '../components/NoisePage'

export default {
  name: 'HomeView',

  components: {
    NoisePage
  }
}
</script>
